<template>

  <div>
    <!-- Search -->
    <b-col cols="12" md="12" class="d-flex justify-content-md-end">
      <div class="">
        <span style="font-weight: bold">{{ msg('Total Brutto') }} </span>
        <span style="font-weight: bold;color: #00acc1">{{ convertToEURO(totalAmount) }}</span>
        <span class="mr-1"></span>
      </div>
    </b-col>
    <b-col cols="12" md="12" class="d-flex justify-content-md-end">
      <div class="">
        <span style="font-weight: bold">{{ msg('tax') }} </span>
        <span style="font-weight: bold;color: #00acc1">{{ convertToEURO(totalTax) }}</span>
        <span class="mr-1"></span>
      </div>
    </b-col>
    <!-- Search -->
    <b-col cols="12" md="12" class="d-flex justify-content-md-end mb-2">
      <div class="">
        <span style="font-weight: bold">{{ msg('totallocaltax') }} </span>
        <span style="font-weight: bold;color: #00acc1">{{ convertToEURO(totalCityTax) }}</span>
        <span class="mr-1"></span>
      </div>
    </b-col>
    <b-table
        ref="invoice-table"
        striped
        responsive
        v-bind:table-class="{'action-active':selectedInvoices.length > 0}"

        v-bind:items="invoices"
        v-bind:fields="fields"
        :busy="loading"
        @sort-changed="onSort"

    >

      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mx-1"></b-spinner>
          <strong>{{ msg('Loading') }}...</strong>
        </div>
      </template>
      <!--Actions-->
      <template #thead-top="data">
        <tr v-if="selectedInvoices.length > 0">
          <th>
            <b-form-checkbox class="d-inline-block" v-bind:checked="isAllInvoicesSelected"
                             v-bind:indeterminate="!isAllInvoicesSelected && selectedInvoices.length>0"
                             @change="selectedInvoices"
            />
          </th>
          <!-- Add this part for the loading sign -->

          <th :colspan="data.columns - 3">
            <div>
              <!--                  <b-button variant="success" size="sm" class="mr-1">
                                  <i class="fas fa-stop mx-1"></i>{{ msg('Enable') }}
                                </b-button>-->
              <b-button variant="danger" size="sm" class="mr-1" @click="downloadInvoices">
                <i class="fas fa-stop mx-1"></i>{{ msg('Download invoices') }}
              </b-button>

              <b-badge variant="secondary" size="sm" class="mr-1">
                <span>selected:</span> <span>{{ selectedInvoices.length }}</span>
              </b-badge>


            </div>
          </th>
          <th></th>
        </tr>
      </template>
      <!--Head selected-->
      <template #head(select)>
        <b-form-checkbox v-bind:checked="isAllInvoicesSelected"
                         v-bind:indeterminate="!isAllInvoicesSelected && selectedInvoices.length>0"
                         @change="toggleAllInvoices"
        />
      </template>
      <template #cell(select)="data">
        <b-form-checkbox
            v-model="selectedInvoiceIds" :value="invoices[data.index].id"
        />
      </template>
      <template #cell(clientId)="data">
        <div v-if="clients[data.value] != null">
          <b-badge variant="secondary" @click="onClientSelect(clients[data.value].id)">{{
              clients[data.value].firstname
            }}
            {{ clients[data.value].lastname }}
          </b-badge>
        </div>
        <div v-else>
          <b-badge variant="warning">{{ msg('Anonymous') }}</b-badge>
        </div>
      </template>

      <template #cell(country)="data">
        <div v-if="clients[data.item.clientId]!=null">
          <p v-if=" clients[data.item.clientId].country!=null"
             style="font-weight: bold">
            {{ $t(`countryCode.${clients[data.item.clientId].country}.name`) }}
          </p>

          <p style="font-weight: bold" v-else-if="getCountryName(clients[data.item.clientId].phone)!=null">
            {{ $t(`countryCode.${getCountryName(clients[data.item.clientId].phone)}.name`) }} </p>

        </div>
        <span v-else style="font-weight: bold;color: #810707">
          {{ $t('No data') }}
        </span>
      </template>

      <template #cell(creationDate)="data">
        {{ $moment(data.value, 'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}
      </template>

      <template #cell(from)="data">
        <span style="font-weight: bold">{{ $moment(data.value, 'YYYY-MM-DD').format("DD.MM.YY") }}</span>
      </template>
      <template #cell(until)="data">
        <span style="font-weight: bold">{{ $moment(data.value, 'YYYY-MM-DD').format("DD.MM.YY") }}</span>
      </template>

      <template #cell(nights)="data">
        <span style="font-weight: bold">{{
            $moment(data.item.until, 'YYYY-MM-DD').diff($moment(data.item.from, 'YYYY-MM-DD'), 'days')
          }}</span></template>

      <template #cell(amount)="data">
        {{ convertToEURO(data.value) }}
      </template>

      <template #cell(status)="data" class="p-0">
        <b-badge v-if="data.value === 'PAID' && !isTodayOrLater(data.item.from)"
                 :variant="statusVariants[data.value]"
                 style="color: black;background-color: #eec33e">{{ msg(data.value) }}
        </b-badge>
        <b-badge v-else :variant="statusVariants[data.value]">{{ msg(data.value) }}</b-badge>
      </template>

      <template #cell(paidTax)="data" class="p-0">
        <b-badge v-if="data.value!=null&&data.value" :variant="'success'">
          {{ convertToEURO(calcLocalTax(data.item.amount, data.item.dynamicId2)) }} ({{ msg('Paid') }})
        </b-badge>
        <b-badge v-else :variant="'danger'"> {{ convertToEURO(calcLocalTax(data.item.amount, data.item.dynamicId2)) }}
          ({{
            msg('Not paid')
          }})
        </b-badge>
      </template>

      <template #cell(system_comment)="data" class="p-0">
        <span v-if="data.item.system_comment!=null">
          {{ data.value }}
        </span>
        <span style="white-space: nowrap" v-else>
          {{ msg('No comment') }}
        </span>
      </template>

      <template #cell(tax)="data" class="p-0">
        <span>{{ convertToEURO(calcTax(data.item.amount, data.item.dynamicId2)) }}
        </span>
      </template>
      <template #cell(creationDate)="data" class="p-0">
        <span>
                  {{ $moment(data.value, 'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}

        </span>
      </template>
      <template #cell(id)="data" class="p-0">
  <span>
    <template
        v-if="contractsMap[data.item.dynamicId]!=null && hasPdf(contractsMap[data.item.dynamicId].files) === 'PDF available'">
      <i class="fas fa-check-circle" style="color: green;"></i> <!-- Green check icon -->
    </template>
    <template v-else>
      <i class="fas fa-exclamation-triangle" style="color: red;">
      </i> <!-- Red triangle icon -->
    </template>
  </span>
      </template>


      <template #cell(dynamicId)="data" class="p-0">
        <b-link @click="openContract(data.value)" style="white-space: nowrap">{{ msg('toTheContract') }}</b-link>
      </template>

      <template #cell(actions)="row">
        <div class="d-flex">
          <b-button v-if="row != null" size="sm" @click="onRowClick(row.item, row.index, $event.target)"
                    class="btn btn-warning mr-1">
            {{ msg('Invoice') }}
          </b-button>

          <b-button v-if="row != null && row.item.system_comment==null" size="sm" class="mr-1"
                    @click="openLocalTaxConfirmation(row.item, row.index, $event.target)"
                    variant="primary">
            {{ msg('localtax') }}
          </b-button>

          <b-button v-if="row != null && row.item.system_comment==null &&
          contractsMap[row.item.dynamicId]!=null&&
           hasPdf(contractsMap[row.item.dynamicId].files) !== 'PDF available'" size="sm"
                    @click="generatePdfInvoice(row.item.dynamicId)"
                    variant="success">
            {{ msg('Generate PDF') }}
          </b-button>
        </div>
      </template>

    </b-table>
    <b-modal :id="taxModal.id" :title="msg('Confirmation')" @ok="payTax(taxModal.monetaryId)"
             ok-variant="danger" :cancel-title="msg('cancel')" @hide="resetTaxModal">
      <p class="my-4">{{ msg('confirmtaxpayment') }}</p>
    </b-modal>

    <div class="text-center w-100">
      <div class="d-inline-block">
        <b-pagination
            v-model="page.currentPage"
            :total-rows="page.total"
            :per-page="page.amount"
            first-number
            last-number
            @input="refresh"
        />
      </div>
      <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import parsePhoneNumberFromString from "libphonenumber-js";

export default {
  name: 'invoices-table',
  data() {
    return {
      loading: true,
      invoices: [],
      contractsMap: {},
      propertyMap: {},
      buildingMap: {},
      clients: {},
      downloadUrl: "",
      totalAmount: 0.0,
      totalTax: 0.0,
      totalCityTax: 0.0,
      selectedInvoices: [],

      taxModal: {
        id: 'tax-modal',
        title: '',
        content: ''
      },
      sort: {by: 'creationDate', direction: 'desc',},
      page: {amount: 10, currentPage: 1, total: 0},
      statusVariants:
          {
            "NEW": "primary",
            "CASH_OPEN": "primary",
            "PENDING": "warning",
            "SHIPPED": "success",
            "EXPIRED": "danger",
            "PAID": "success",
            "CASH_TAKEN": "danger",
            "CASH_IN": "success",
            "PAID_CASH": "success",
            "WAITING_FOR_PAYMENT": "warning",
            "PAYMENT_FAILED": "danger",
            "CANCELLED": "danger",
            "COMPLETED": "success"
          },

      //Listeners
      listeners: {
        refresh: () => {
        }
      }
    }
  },
  props: {
    filter: {
      default: () => {
      },
    },
    fieldsFilter: {
      default: () => [],
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
    selectedInvoices: {
      handler() {
        this.$emit("selectedInvoices", [...this.selectedInvoices])
      }
    }
  },
  created() {
    let $this = this
    //this.getData(true)
    this.refresh()
    this.$root.$on('crm::invoice-table::refresh', $this.listeners.refresh = () => {
      $this.refresh()
    })
    this.$root.$on('crm::branch-updated', $this.listeners.refresh)

  },
  beforeDestroy() {
    this.$off('crm::invoice-table::refresh', this.listeners.refresh)
    this.$off('crm::branch-updated', this.listeners.refresh)
  },
  computed: {
    ...mapGetters('data', ['getBranchName']),
    isAllInvoicesSelected() {
      let ids = this.selectedInvoices.map(p => p.id);
      return this.invoices.filter(p => !ids.includes(p.id)).length === 0;
    },
    selectedInvoiceIds: {
      get() {
        return this.selectedInvoices.map(p => p.id);
      },
      set(selectedInvoiceIds) {
        let invoices = this.invoices.toObject("id");
        this.selectedInvoices = selectedInvoiceIds.map(pid => invoices[pid]);
      }
    },
    fields() {
      let $this = this
      let fields = [
        {
          key: 'select',
          label: $this.msg('Bulk select'),
          sortable: false,
          active: true,
        },
        {
          key: 'actions',
          label: $this.msg('Actions'),
          sortable: true
        },
        {
          key: 'id',
          label: $this.msg('PDF'),
          sortable: true
        },

        {
          key: 'dynamicId',
          label: $this.msg('Contract'),
          sortable: false
        },
        {
          key: 'invoiceNumber',
          label: $this.msg('Invoice number'),
          sortable: false
        },
        {
          key: 'clientId',
          label: $this.msg('Client'),
          sortable: false
        },
        {
          key: 'country',
          label: $this.msg('country'),
          sortable: false
        },

        {
          key: 'amount',
          label: $this.msg('Total'),
          sortable: false
        }, {
          key: 'platform',
          label: $this.msg('Platform'),
          sortable: false
        },
        {
          key: 'status',
          label: this.msg('status'),
          sortable: true
        },
        {
          key: 'creationDate',
          label: $this.msg('creationTime'),
          sortable: false
        },
        {
          key: 'paidTax',
          label: $this.msg('localtax'),
          sortable: false
        },
        {
          key: 'tax',
          label: 'UST',
          sortable: false
        },


        {
          key: 'nights',
          label: $this.msg('Nights'),
          sortable: true
        },
        {
          key: 'system_comment',
          label: $this.msg('Comment'),
          sortable: true
        },

        {
          key: 'from',
          label: $this.msg('CheckIn'),
          sortable: false
        },
        {
          key: 'until',
          label: $this.msg('CheckOut'),
          sortable: false
        },


      ]
      if (!Array.isArray(this.fieldsFilter) || this.fieldsFilter.length === 0) {
        return fields
      }
      return fields.filter(f => $this.fieldsFilter.includes(f.key))
    },
  },
  methods: {
    ...mapActions('monetary', ['getAllMonetary', 'getData', 'payMonetaryTax', 'getPdfInvoicesUrl']),
    ...mapActions('clients', ['getClients']),
    ...mapActions('realEstateContracts', ['createPdfInvoiceContract']),

    getCountryName(phone) {
      const parsedPhoneNumber = parsePhoneNumberFromString(phone);
      if (parsedPhoneNumber) {
        return parsedPhoneNumber.country;
      } else {
        return null;
      }
    },
    toggleAllInvoices(estate) {
      if (estate)
        this.selectedInvoices = [...this.invoices];
      else
        this.selectedInvoices = [];
    },

    hasPdf(fileList) {
      if (fileList == null || fileList.length === 0) {
        return 'No PDF';
      }
      // Check if any object in the fileList has a field named "tag" with value "generatedInvoice"
      const hasGeneratedInvoice = fileList.some(file => file.tag === 'generatedInvoice');
      if (hasGeneratedInvoice) {
        return 'PDF available';
      } else {
        return 'No PDF';
      }
    },
    isTodayOrLater(dateString) {
      if (dateString == null)
        return false
      const currentDate = new Date();
      const parsedStartDate = new Date(dateString);

      // Compare dates
      return currentDate >= parsedStartDate;
    },
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    refresh() {
      this.loading = true;

      let $this = this
      this.getAllMonetary({
        page: this.page.currentPage,
        amountPerPage: this.page.amount,
        filter: this.filter,
        sortby: this.sort.direction,
        sortcolumn: this.sort.by
      }).then($this.onInvoicesRetrieve)
    },
    onInvoicesRetrieve({invoices, properties, buildings, contracts, amountPerPage, total, page}) {
      let $this = this
      console.log("onInvoicesRetrieve start...")

      this.invoices = invoices.filter(invoice => invoice.amount > 0.99 && invoice.from != null && invoice.system_comment!=='deposit')
      this.contractsMap = contracts;
      this.propertyMap = properties;
      this.buildingMap = buildings;
      this.page.total = total;
      this.page.currentPage = page;
      this.page.amount = amountPerPage;
      console.log("onInvoicesRetrieve end...")

      this.retrieveClients(invoices);
    },
    retrieveClients(invoices) {
      console.log("retrieveClients start...")

      let $this = this
      let ids = invoices.map((invoice) => invoice.clientId)
      this.getClients({
        page: 1,
        amountPerPage: -1,
        filter: {ids}
      }).then((clientData) => {


        $this.clients = clientData.clients.toObject('id')
        $this.loading = false
        console.log("retrieveClients end...")

        $this.handleData()
      })
    },

    handleData() {
      const dataToSend = {
        key: this.invoices, // Your data object here
      };
      this.$emit('custom-event-name', dataToSend);

      // Initialize variables to accumulate total values
      let totalAmount = 0;
      let totalCityTax = 0;
      let totalTax = 0;

      // Iterate over each invoice to calculate the taxes by propertyId
      this.invoices.forEach(invoice => {
        // Check if invoice is not paid tax and has no system comment
        if (invoice.paidTax === null || !invoice.paidTax ) {
          const amount = invoice.amount;
          const propertyId = invoice.dynamicId2; // Get the propertyId

          // Calculate taxes using the functions you provided
          const tax = this.calcTax(amount, propertyId); // Calculate VAT
          const localTax = this.calcLocalTax(amount, propertyId); // Calculate city tax

          // Add the amounts to the totals
          totalAmount += amount; // Total amount
          totalCityTax += parseFloat(localTax); // Total local tax
          totalTax += parseFloat(tax); // Total VAT tax
        }
      });

      console.log("totalAmount: ", totalAmount);
      console.log("totalCityTax: ", totalCityTax);
      console.log("totalTax: ", totalTax);

      // Set the computed totals
      this.totalAmount = totalAmount;
      this.totalCityTax = totalCityTax;
      this.totalTax = totalTax;
    },

    calcTax(amount, propertyId) {
      if (propertyId == null || this.buildingMap[propertyId] == null || this.buildingMap[propertyId].vat == null || this.buildingMap[propertyId].vat <= 0) {
        return (amount - (amount / 1.1)).toFixed(2);
      }

      let vat = this.buildingMap[propertyId].vat;
      return (amount - (amount / (1 + vat / 100))).toFixed(2);
    },
    calcLocalTax(amount, propertyId) {
      if (propertyId == null || this.buildingMap[propertyId] == null || this.buildingMap[propertyId].cityTax == null || this.buildingMap[propertyId].cityTax <= 0) {
        return (amount* 0.025237).toFixed(2);
      }

      let cityTax = this.buildingMap[propertyId].cityTax;
      return (amount * cityTax / 100).toFixed(2);
    },
    onClientSelect(clientId) {
      this.$root.$emit('bv::hide::popover')
      this.$root.$emit('crm::client::select', clientId)
    },
    onSort(data) {
      this.sort.by = data.sortBy
      this.sort.direction = data.sortDesc ? 'desc' : 'asc'
      this.refresh()
    },
    updatePageAmount(amount) {
      this.page.amount = amount;
      this.refresh();
    },
    onRowClick(item, index, button) {
      window.open(`/report/monetary/invoice/${item.id}`, '_blank');
    },
    openContract(value) {
      window.open(`/real_estate/contracts/rent/${value}`, '_blank');
    },
    openLocalTaxConfirmation(item, index, button) {
      this.taxModal.monetaryId = item.id
      this.$root.$emit('bv::show::modal', this.taxModal.id, button)
    },
    generatePdfInvoice(contractId) {
      let $this = this;
      this.createPdfInvoiceContract(contractId)
          .then(() => {
            setTimeout(() => {
              $this.refresh();
            }, 3000); // 3000 milliseconds = 3 seconds
          });
    }
    ,
    downloadInvoices() {
      let $this = this;
      this.getPdfInvoicesUrl(this.selectedInvoiceIds).then(
          (url) => {
            $this.downloadUrl = url;
            window.open($this.downloadUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
          }
      );
    },
    payTax(monetaryId) {
      let $this = this

      this.payMonetaryTax({
        id: monetaryId
      })
          .then(
              () => {
                $this.refresh()
              }
          )
    },

    resetTaxModal() {
      this.$root.$emit('bv::hide::modal', this.taxModal.id)
    },

  }
}
</script>

<style scoped>

</style>
